import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import CreateNewWorkflowComponent from "./CreateNewWorkflowComponent";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import ShimmerLoader from "../../../_common/ShimmerLoader";
import UpdateWorkflowComponent from "./UpdateWorkflowComponent";
import LoadingSpinner from "../../../_common/LoadingSpinner";
import Commonfield from "../../../../infrastructure/core/Commonfield";

function WorkflowComponent() {
  const axiosPrivate = useAxiosPrivate();
  const [addNewWorkflowComponent, setAddNewWorkflowComponent] = useState(false);
  const [updateWorkflowComponent, setUpdateWorkflowComponent] = useState(false);
  const [workflowComponentDetail, setWorkflowComponentDetail] = useState({});
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [workflowComponentList, setWorkflowComponentList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const columns = [
    {
      name: "Label",
      selector: (row) => row.label,
      sortable: true,
      cell: (row) => <div>{row.label}</div>,
    },
    {
      name: "Component Type Name",
      selector: (row) => row.componentTypeName,
      sortable: true,
      cell: (row) => <div>{row.componentTypeName}</div>,
    },
    {
      name: "Data type",
      selector: (row) => (
        row?.datatypeName
      ),
      sortable: true,
      cell: (row) => (
        <div>
          {row?.datatypeId !== Commonfield.defaultValue ? row?.datatypeName :"-"}
        </div>
      ),
    },
    {
      name: "Component Value Count",
      selector: (row) => row?.componentValues?.length,
      sortable: true,
      cell: (row) => <div>{row?.componentValues?.length}</div>,
    },
    {
      name: "Excel Header",
      selector: (row) => row?.excelHeader === "" ? "-":row?.excelHeader,
      sortable: true,
      cell: (row) => <div>{row?.excelHeader === "" ? "-":row?.excelHeader}</div>,
    },
    {
      name: "Created By",
      selector: (row) => row?.createdByName,
      sortable: true,
      cell: (row) => <div>{row?.createdByName}</div>,
    },
    {
      name: "Created On",
      selector: (row) => row?.createdOn,
      sortable: true,
      cell: (row) => <div>{new Date(row?.createdOn).toLocaleString()}</div>,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
          <Button
              variant="primary"
              type="button"
              className="btn-icon"
              onClick={() => {
                getComponentDetails(row.id);
              }}
              disabled={
                loading.isLoading &&
                loading.value === `getComponentDetails${row.id}`
              }
            >
              {loading.isLoading &&
              loading.value === `getComponentDetails${row.id}` ? (
                <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
              ) : (
                <i className="ri-pencil-line"></i>
              )}
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const filteredData = workflowComponentList?.filter((item) => {
    return (
      item?.label?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.componentTypeName?.toLowerCase().includes(searchText.toLowerCase()) ||
      (item?.datatypeId !== Commonfield.defaultValue ? item?.datatypeName :"-")?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.componentValues?.length?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase()) 
    );
  });

  useEffect(() => {
    getWorkflowComponentList();
  }, []);
  const getWorkflowComponentList = async () => {
    try {
      setLoading({ isLoading: true, value: "getWorkflowComponentList" });
      const workflowListData = await axiosPrivate.get(`Setting/get-workflow-component-List`);
      console.log("workflowListData",workflowListData?.data)
      setWorkflowComponentList(workflowListData?.data);
    } catch (err) {
      setLoading({ isLoading: false, value: "getWorkflowComponentList" });
    } finally {
      setLoading({ isLoading: false, value: "getWorkflowComponentList" });
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const getComponentDetails=async(id)=>{
    try {
      setLoading({ isLoading: true, value: `getComponentDetails${id}` });
      const workflowComponentDetail = await axiosPrivate.get(
        `Setting/get-workflow-component-details/${id}`
      );
      setWorkflowComponentDetail(workflowComponentDetail?.data);
    } catch (err) {
      setLoading({ isLoading: false, value: `getComponentDetails${id}` });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: `getComponentDetails${id}` });
      setUpdateWorkflowComponent(true);
    }
  }
  return (
    <React.Fragment>
      <CreateNewWorkflowComponent
        show={addNewWorkflowComponent}
        setWorkflowComponentList={setWorkflowComponentList}
        closeFunction={setAddNewWorkflowComponent}
      />
      <UpdateWorkflowComponent
        show={updateWorkflowComponent}
        setWorkflowComponentList={setWorkflowComponentList}
        closeFunction={setUpdateWorkflowComponent}
        workflowComponentDetail={workflowComponentDetail}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Workflow Components</h4>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => setAddNewWorkflowComponent(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add Components</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {loading.isLoading && loading.value === "getWorkflowComponentList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default WorkflowComponent;
