import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function PriorAuthorization(props) {
    return (
        <React.Fragment>
            <div className="claim-information-wrapper">
                <Card className="mb-3">
                    <span className="divider-name">Prior Authorization</span>
                    <Card.Body className="pt-4 pb-4">
                        <Row className="g-3">
                            <Col xl={2}>
                                <h5 className="fs-14 mb-1">PA Closed Type</h5>
                                <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                            </Col>
                            <Col xl={2}>
                                <h5 className="fs-14 mb-1">PA Authorization Token</h5>
                                <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}