import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import LoadingSpinner from "../../../_common/LoadingSpinner";

function UpdateWorkflowField(props) {
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({
    title: "",
  });
  const [loading, setLoading] = useState({ isLoading: false, value: "" });

  useEffect(() => {
    if (props?.workflowFieldDetail) {
      setFormData({
        id: props?.workflowFieldDetail.id,
        title: props?.workflowFieldDetail.title,
      });
    }
  }, [props?.workflowFieldDetail]);

  const handleSubmit = async () => {
    console.log("formData", formData);
    try {
      setLoading({ isLoading: true, value: "UpdateWorkflowField" });
      const workflowFieldListData = await axiosPrivate.post(
        `Setting/update-workflow-field`,
        formData
      );
      props?.setWorkflowFieldList(workflowFieldListData?.data?.data);
      props?.closeFunction();
    } catch (err) {
      setLoading({ isLoading: false, value: "UpdateWorkflowField" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "UpdateWorkflowField" });
    }
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() => props.closeFunction()}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Update Field
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Field Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Field Title"
                  name="title"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      title: e.target.value, // Updated to e.target.value for text input
                    }))
                  }
                  value={formData.title}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={
              loading.isLoading && loading?.value === "UpdateWorkflowField"
            }
            onClick={handleSubmit}
          >
            {loading.isLoading && loading?.value === "UpdateWorkflowField" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

export default UpdateWorkflowField;
