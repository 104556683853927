import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { Button, Col, Offcanvas, Row, Form, Spinner } from "react-bootstrap";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Commonfield from "../../../../infrastructure/core/Commonfield";
import LoadingSpinner from "../../../_common/LoadingSpinner";
const CustomDropdownIndicator = ({ loading, ...props }) => (
  <components.DropdownIndicator {...props}>
    {loading ? <Spinner animation="border" size="sm" className="ms-2" /> : null}
  </components.DropdownIndicator>
);

function CreateNewWorkflowComponent(props) {
  const axiosPrivate = useAxiosPrivate();
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({
    label: "",
    componentType: Commonfield.defaultValue,
    componentValue: [],
    datatypeId: Commonfield.defaultValue,
    notes: "",
    excelHeader: "",
  });
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [workflowFieldList, setWorkflowFieldList] = useState([]);
  const datatypeList = JSON.parse(sessionStorage.getItem("commonField"))
    ?.find((cmf) => cmf.typeGroupCode === "DTYP" )
    ?.fieldValues?.filter((cmf=> cmf.id!=="4e0073a0-2339-4acf-bbd7-6b265bed7f07"))?.map((dtyflds) => ({
      value: dtyflds.id,
      label: dtyflds.value,
    }));

  const componentTypeList = JSON.parse(sessionStorage.getItem("commonField"))
    ?.find((cmf) => cmf.typeGroupCode === "CMPTYP")
    ?.fieldValues?.map((cmptflds) => ({
      value: cmptflds.id,
      label: cmptflds.value,
    }));

  const getWorkflowFieldList = async () => {
    try {
      setLoading({ isLoading: true, value: "getWorkflowFieldList" });
      const workflowFieldListData = await axiosPrivate.get(
        `Setting/get-workflow-field-List`
      );
      setWorkflowFieldList(
        workflowFieldListData?.data?.map((wff) => ({
          value: wff.id,
          label: wff.title,
        }))
      );
    } catch (err) {
      setLoading({ isLoading: false, value: "getWorkflowFieldList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getWorkflowFieldList" });
    }
  };

  useEffect(() => {
    if (props.show) {
      resetForm();
      getWorkflowFieldList();
    }
  }, [props.show]);

  const resetForm = () => {
    setFormData({
      label: "",
      componentType: Commonfield.defaultValue,
      componentValue: [],
      datatypeId: Commonfield.defaultValue,
      notes: "{data}",
      excelHeader: "",
    });
  };

  const handleCreateComponent = async () => {
    const componentValueIds = formData.componentValue.map(
      (option) => option.value
    );
    const submittedData = {
      ...formData,
      componentValue: componentValueIds,
    };
    try {
      setLoading({ isLoading: true, value: "handleCreateComponent" });
      const workflowComponentListData = await axiosPrivate.post(
        `Setting/add-workflow-component`,
        submittedData
      );
      props?.setWorkflowComponentList(workflowComponentListData?.data?.data);
      resetForm();
      props.closeFunction();
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "handleCreateComponent" });
    } finally {
      setLoading({ isLoading: false, value: "handleCreateComponent" });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const dataString = "{data}";
    const dataStart = value.indexOf(dataString);
    const dataEnd = dataStart + dataString.length;
    if (!value.includes(dataString)) {
      setFormData({ notes: dataString });
      return;
    }
    const cursorPosition = e.target.selectionStart;
    if (cursorPosition > dataStart && cursorPosition < dataEnd) {
      e.preventDefault();
      inputRef.current.setSelectionRange(dataEnd, dataEnd);
    } else {
      setFormData({ notes: value });
    }
  };

  const handleFocus = (e) => {
    const dataString = "{data}";
    const dataStart = e.target.value.indexOf(dataString);
    const dataEnd = dataStart + dataString.length;
    const cursorPosition = e.target.selectionStart;
    if (cursorPosition > dataStart && cursorPosition < dataEnd) {
      inputRef.current.setSelectionRange(dataEnd, dataEnd);
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() => props.closeFunction()}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create Component
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Component Label <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Component Label"
                  name="label"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      label: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Component Type<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      componentType: e.value,
                    }));
                    if (
                      e.value === "4e0073a0-2338-4acf-bbd7-6b265bed7f01" ||
                      e.value === "4e0073a0-2338-4acf-bbd7-6b265bed7f05"
                    ) {
                      setFormData((prevData) => ({
                        ...prevData,
                        componentValue: [],
                      }));
                    } else {
                      setFormData((prevData) => ({
                        ...prevData,
                        datatypeId: Commonfield.defaultValue,
                      }));
                    }
                  }}
                  options={componentTypeList}
                  placeholder="Select Component Type"
                  name="componentType"
                  isDisabled={
                    loading.isLoading &&
                    loading.value === "getWorkflowFieldList"
                  }
                  components={{
                    DropdownIndicator: (props) => (
                      <CustomDropdownIndicator
                        {...props}
                        loading={
                          loading.isLoading &&
                          loading.value === "getWorkflowFieldList"
                        }
                      />
                    ),
                  }}
                />
              </div>
            </Col>
          </Row>
          {!(
            formData.componentType === Commonfield.defaultValue ||
            formData.componentType === "4e0073a0-2338-4acf-bbd7-6b265bed7f01" 
          ) && (
            <Row>
              <Col>
                <div className="mb-4">
                  <Form.Label>
                    Component Value<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    placeholder="Select Component value"
                    options={workflowFieldList}
                    isSearchable={true}
                    isMulti={true}
                    value={formData.componentValue}
                    name="componentValue"
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        componentValue: e,
                      }))
                    }
                  />
                </div>
              </Col>
            </Row>
          )}
          {formData.componentType ===
            "4e0073a0-2338-4acf-bbd7-6b265bed7f01" && (
            <Row>
              <Col>
                <div className="mb-4">
                  <Form.Label>
                    Data type<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        datatypeId: e.value,
                      }))
                    }
                    options={datatypeList}
                    placeholder="Select Datatype"
                    name="datatypeId"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Notes <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Component Notes"
                  name="notes"
                  value={formData.notes}
                  ref={inputRef}
                  onChange={handleInputChange}
                  onClick={handleFocus}
                  onKeyDown={handleFocus}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-4">
                <Form.Label>
                  Excel Header <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Excel Header"
                  name="excelHeader"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      excelHeader: e.target.value,
                    }))
                  }
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleCreateComponent}
            disabled={
              loading.isLoading && loading.value === "handleCreateComponent"
            }
          >
            {loading.isLoading && loading?.value === "handleCreateComponent" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Create</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

export default CreateNewWorkflowComponent;
