import React, { useEffect, useState } from "react";
import "./details.scss";
import {
  Offcanvas,
  Row,
  Col,
  Card,
  Badge,
  Button,
  Dropdown,
} from "react-bootstrap";
import DocumentUpload from "./document-upload";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ClaimInformation from "./claim-information";
import PriorAuthorization from "./prior-authorization";
import InsuranceInformation from "./insurance-information";
import CaseDisposition from "./CaseDisposition/case-disposition";
import PayerClosedModal from "../payer-closed-modal";
import WorkLaterModal from "../work-later-modal";
import TLQueryModal from "../TL-query-modal";
import { Link } from "react-router-dom";
import LabReferringProviderInfo from "./lab-referring-pro-info";
import DebialCorerespondence from "./debial-corerespondence";

export default function MyTasksDetails(props) {
  const axiosPrivate = useAxiosPrivate();
  const taskId = props?.taskId;
  const [taskDetails, setTaskDetails] = useState({});
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);

  const [showPayerClosed, setShowPayerClosed] = useState(false);
  const [showWorkLater, setShowWorkLater] = useState(false);
  const [showTlQuery, setShowTlQuery] = useState(false);

  useEffect(() => {
    if (taskId) {
      getTaskDetails(taskId);
    }
  }, [taskId]);
  const getTaskDetails = async () => {
    const taskDetail = await axiosPrivate.get(`Task/case-details/${taskId}`);
    setTaskDetails(taskDetail?.data?.result);
  };
  return (
    <React.Fragment>
      <PayerClosedModal
        show={showPayerClosed?.showBoolPayerClosed}
        closeFunction={setShowPayerClosed}
        taskId={taskId}
        visitId={taskDetails?.visitId}
        setTeamLeadQueries={props?.setTeamLeadQueries}
        setTaskSummary={props?.setTaskSummary}
        setPendingTasks={props?.setPendingTasks}
        setParkedTasks={props?.setParkedTasks}
        setMyTask={props?.setMyTask}
        setShowTasksDetails={props?.setShowTasksDetails}
      />
      <WorkLaterModal
        show={showWorkLater?.showBoolWorkLater}
        closeFunction={setShowWorkLater}
        taskId={taskId}
        visitId={taskDetails?.visitId}
        setTeamLeadQueries={props?.setTeamLeadQueries}
        setTaskSummary={props?.setTaskSummary}
        setPendingTasks={props?.setPendingTasks}
        setParkedTasks={props?.setParkedTasks}
        setMyTask={props?.setMyTask}
        setShowTasksDetails={props?.setShowTasksDetails}
      />
      <TLQueryModal
        show={showTlQuery?.showBoolTLQuery}
        closeFunction={setShowTlQuery}
        taskId={taskId}
        visitId={taskDetails?.visitId}
        setTeamLeadQueries={props?.setTeamLeadQueries}
        setTaskSummary={props?.setTaskSummary}
        setPendingTasks={props?.setPendingTasks}
        setParkedTasks={props?.setParkedTasks}
        setMyTask={props?.setMyTask}
        setShowTasksDetails={props?.setShowTasksDetails}
      />
      <Offcanvas
        show={props.show}
        onHide={() =>
          props?.setShowTasksDetails({ showTaskDetails: false, taskId: "" })
        }
        placement="end"
        className="w-100"
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">
            <Link
              onClick={() =>
                props?.setShowTasksDetails({
                  showTaskDetails: false,
                  taskId: "",
                })
              }
              style={{ zIndex: 5 }}
              className="align-middle text-dark me-2 position-relative"
            >
              <i className="ri-arrow-left-s-line fs-28"></i>
            </Link>
            <span className="align-middle">Case Details</span>
          </Offcanvas.Title>

          <div className="d-flex align-items-center gap-2">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-1"
              onClick={() =>
                setShowUploadDocModal({
                  showUploadModelBool: true,
                  caseId: taskDetails.id,
                  taskBucketQueueId: taskId,
                  visitId: taskDetails.visitId,
                })
              }
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline">Upload Document</span>
            </Button>

            {props.setTeamLeadQueries.length + props.setParkedTasks.length <=
              10 ? (
              <div className="d-flex justify-content-end">
                <Dropdown className="me-2">
                  <Dropdown.Toggle
                    variant="danger"
                    className="custom-dropdown-action ht-30 pe-2 ps-2 pt-0 pb-0 ht-35"
                  >
                    Park Case
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        setShowPayerClosed({
                          showBoolPayerClosed: true,
                          taskId: props?.id,
                          visitId: props?.visitID,
                        })
                      }
                    >
                      Payer closed
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setShowWorkLater({
                          showBoolWorkLater: true,
                          taskId: props?.id,
                          visitId: props?.visitID,
                        })
                      }
                    >
                      Work later
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setShowTlQuery({
                          showBoolTLQuery: true,
                          taskId: props.id,
                          visitId: props.visitID,
                        })
                      }
                    >
                      TL Query
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant="default"
                  onClick={() =>
                    props?.setShowTasksDetails({
                      showTaskDetails: false,
                      taskId: "",
                    })
                  }
                >
                  <i className="ri-close-line"></i>
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  variant="default"
                  onClick={() =>
                    props?.setShowTasksDetails({
                      showTaskDetails: false,
                      taskId: "",
                    })
                  }
                >
                  <i className="ri-close-line"></i>
                </Button>
              </div>
            )}
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Row>
              <Col md={8} className="p-0">
                <Card className="card-one case-details-card h-auto">

                  <div className="mb-4 position-sticky bg-white top-0 z-index4 p-2 pb-3">
                    <h2 className="fs-18 fw-semibold text-dark">{taskDetails?.patientName}</h2>
                    <div className="d-flex align-items-center gap-2 mb-3">
                      <span className="fs-12 text-dark">
                        {/* {taskDetails?.patientGender} */}
                        {taskDetails?.patientGender === "F" && (<b>Fmale</b>)}{" "}
                        {taskDetails?.patientGender === "M" && <b>Male</b>}{" "}
                        {taskDetails?.patientGender === "" && (<b>{taskDetails?.patientGender}</b>)}
                      </span>
                      <span className="text-black-50">|</span>
                      <span className="fs-12 text-dark"><b>Age:</b> {taskDetails?.patientAge}</span>
                      <span className="text-black-50">|</span>
                      <span className="fs-12 text-dark">{taskDetails?.mobileNo}</span>
                    </div>

                    <Badge bg="danger" className="rounded fs-14 mb-3 me-2">{taskDetails?.taskPriority}</Badge>
                    <Badge bg="primary" className="rounded fs-14 mb-3">{taskDetails?.taskBucketName}</Badge>

                    <Card className="mb-3 card-one">
                      <Card.Body>
                        <Row className="flex-wrap g-3">
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Visit ID</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetails?.visitId}
                            </h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">LIMS Casefile ID</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetails?.referenceID}
                            </h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">LIMS DOS Formal</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetails.dateOfService === "0001-01-01T00:00:00" ? "-" : new Date(taskDetails?.dateOfService).toLocaleString()}
                            </h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Test Name</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetails?.testType}
                            </h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Panel</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetails?.testSubType}
                            </h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Panel Type</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              $ {taskDetails?.chargeAmount}
                            </h4>
                          </Col>

                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">All Procedure Codes</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>

                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Modifier</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>


                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">BDE Risk Category</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>

                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Empower Risk Combined</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>

                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Cancer Type</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>

                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Cancer Stage</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>

                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Organt Type</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">Positive Policy</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">AMD ICD Codes</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>
                          <Col xl={2}>
                            <h5 className="fs-14 mb-1">ZCode</h5>
                            <h4 className="fw-bold text-dark fs-14">Sample Data</h4>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="card-one h-auto">
                      <Card.Body>

                        {/* PRIOR AUTHORIZATION COMPONENT */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">Prior Authorization</h3>
                          <PriorAuthorization />
                        </div>

                        {/* INSURANCE INFORMATION COMPONENT */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">Insurance Information</h3>
                          <InsuranceInformation />
                        </div>

                        {/* LAB/REFERRING PROVIDER INFORMATION */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">LAB/Referring Provider Information</h3>
                          <LabReferringProviderInfo />
                        </div>

                         {/* DEBIAL/CORERESPONDENCE */}
                         <div>
                          <h3 className="fs-14 fw-semibold mb-4">Debial/Corerespondence</h3>
                          <DebialCorerespondence />
                        </div>

                        {/* CLAIM INFORMATION COMPONENT */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">Claim Information</h3>
                          <ClaimInformation claimInfo={taskDetails?.claimInfo} />
                        </div>

                        <hr />
                        <DocumentUpload setShowUploadDocModal={setShowUploadDocModal} showUploadDocModal={showUploadDocModal} taskDetails={taskDetails} />
                      </Card.Body>
                    </Card>

                  </div>


                </Card>
              </Col>
              <Col md={4} className="p-0">
                <Card className="card-one case-details-card">
                  <Card.Body>
                    <CaseDisposition
                      setShowTasksDetails={props.setShowTasksDetails}
                      setPendingTasks={props?.setPendingTasks}
                      taskDetails={taskDetails}
                      setTeamLeadQueries={props?.setTeamLeadQueries}
                      setTaskSummary={props?.setTaskSummary}
                      setParkedTasks={props?.setParkedTasks}
                      setMyTask={props?.setMyTask}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
