import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Form, ButtonGroup, Button } from "react-bootstrap";
import "./case-disposition.scss";
import Select from "react-select";
import actionMode from "../../../../Json/DecisionTree/action-mode.json";
import useAuth from "../../../../hooks/useAuth";
import { axiosPrivate } from "../../../../api/axios";
import ToastifyService from "../../../_common/ToastifyService";

function DAuthorization(props) {
  let myRef = useRef();
  const initialState = {
    statusName: "",
    dateOdDenial: "",
    claimId: "",
    isInNetwork: "",
    IsAuthReq: "",
    IsAuthFile: "",
    IsAuthOnCall: "",
    doesDOSFall: "",
    IsRepProcessing: "",
    ETA: "",
    isOtherInsurance: "",
    InsuranceName: "",
    IsPolicyActive: "",
    ActionId: "",
    AppealLimit: "",
    AppealAddress: "",
    CCTFL: "",
    CallId: "",
    followUpBy: "",
    followUpDays: "",
  };
  const [formData, setFormData] = useState(initialState);
  const { auth } = useAuth();
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActionMode, setSelectedActionMode] = useState("");
  const [subStatusList, setSubStatusList] = useState([]);
  // HANDLE ACTION FUNCTION
  const [filteredActionData, setFilteredActionData] = useState([]);
  // HANDLE ACTION MODE FUNCTION
  const [filteredActionModeData, setFilteredActionModeData] = useState([]);
  const [selectedToggle, setSelectedToggle] = useState("");
  const [reprocessinToggle, setReprocessingToggle] = useState("");
  const [isAuthRequired, setIsAuthRequired] = useState("");
  const [isAuthFile, setIsAuthFile] = useState("");
  const [isAuthOnCall, setIsAuthOnCall] = useState("");
  const subStatusJson = [
    {
      value: 1,
      label: "DENIED INCORRECTLY._INSURANCE REPROCESSING",
    },
    {
      value: 2,
      label: "DENIED INCORRECTLY._INSURANCE DENIED REPROCESSING",
    },
    {
      value: 3,
      label: "AUTH AVAILABLE. PROVIDED OVER CALL",
    },
    {
      value: 4,
      label: "AUTH AVAILABLE. SUBMIT CORRECTED CLAIM",
    },
    {
      value: 5,
      label: "AUTH NOT AVAILABLE. SUBMIT RECONSIDRATION",
    },
  ];

  const ActionJson = [
    {
      value: 1,
      label: "INSURANCE_REPROCESSING",
    },
    {
      value: 2,
      label: "Appeal Sent",
    },
    {
      value: 3,
      label: "INSURANCE_REPROCESSING",
    },
    {
      value: 4,
      label: "CC_REBILLED",
    },
    {
      value: 5,
      label: "RECONSIDRATION SENT",
    },
  ];
  const ActionModeJson = [
    {
      value: 1,
      label: "PAPER",
      followUpdays: 35,
      followUPBy: "AR",
    },
    {
      value: 2,
      label: "TASK",
      followUpdays: 3,
      followUPBy: "BILLING",
    },
    {
      value: 3,
      label: "ELECTRONIC",
      followUpdays: 22,
      followUPBy: "AR",
    },
    {
      value: 4,
      label: "FAX",
      followUpdays: 22,
      followUPBy: "AR",
    },
    {
      value: 5,
      label: "WEB",
      followUpdays: 5,
      followUPBy: "AR",
    },
    {
      value: 6,
      label: "CALL",
      followUpdays: 22,
      followUPBy: "AR",
    },
  ];
  useEffect(() => {
    // // OPTION ACTION MODE DATA

    setFilteredActionModeData(ActionModeJson);
  }, []);

  const handleActionSelect = (event) => {
    setFormData({
      ...formData,
      ActionId: event.value,
      ActionName: event.label,
      ActionModeId: "",
    });
    setSelectedAction(event);
  };

  useEffect(() => {
    setSelectedToggle("");
    setFormData({
      ...formData,
      status: props.selectedStatus.value,
      statusName: props.selectedStatus.label,
    });
    setSubStatusList([]);
  }, [props.selectedStatus]);
  // STATUS FUNCTION

  // SUB STATUS FUNCTION
  const handleSubStatusSelect = (event) => {
    setFormData({
      ...formData,
      SubStatus: event.value,
      subStatusName: event.label,
      ActionId: "",
      ActionModeId: "",
      followUpBy: "",
      followUpDays: "",
    });
    console.log(event);
    setSelectedAction("");

    setSelectedSubStatus(event);
    if (formData.status !== props?.selectedStatus?.value) {
      const actionsList = JSON.parse(sessionStorage.getItem("commonField"))
        ?.find((wqty) => wqty.id === formData.status)
        ?.fieldValues?.find((action) => action.id === event.value)
        ?.commonFieldActions?.map((item) => ({
          value: item.id,
          label: item.actionName,
        }));
      setFilteredActionData(actionsList);
    } else {
      const actionsList = ActionJson.filter(
        (stts) => stts.value === event.value
      ).map((item) => ({
        value: item.value,
        label: item.label,
      }));
      setFilteredActionData(actionsList);
    }
  };

  const handleActionModeSelect = (event) => {
    setSelectedActionMode(event);
    setFormData({
      ...formData,
      ActionModeId: event.value,
      ActionModeName: event.label,
      followUpBy: event.followUPBy,
      followUpDays: event.followUpdays,
    });
  };

  const handleToggle = (value) => {
    setSelectedToggle(value);
    setSelectedSubStatus("");
    setSelectedAction("");
    setIsAuthFile("");
    setIsAuthRequired("");
    setIsAuthOnCall("");
    setSubStatusList([]);
    setFormData({
      ...formData,
      isInNetwork: value,
      IsAuthReq: "",
      IsAuthFile: "",
      IsAuthOnCall: "",
      doesDOSFall: "",
      IsRepProcessing: "",
      ETA: "",
      isOtherInsurance: "",
      ActionModeId: "",
      InsuranceName: "",
      IsPolicyActive: "",
      ActionId: "",
      AppealLimit: "",
      AppealAddress: "",
      CCTFL: "",
      CallId: "",
      followUpBy: "",
      followUpDays: "",
    });
  };
  const handleAuthToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setIsAuthFile("");
    setSubStatusList([]);
    setIsAuthRequired(value);
    setIsAuthOnCall("");
    setReprocessingToggle("");
    setFormData({
      ...formData,
      IsAuthReq: value,
      ActionId: "",
      IsAuthFile: "",
      IsAuthOnCall: "",
      ActionModeId: "",
      doesDOSFall: "",
      IsRepProcessing: "",
      ETA: "",
      isOtherInsurance: "",
      InsuranceName: "",
      IsPolicyActive: "",
      AppealLimit: "",
      AppealAddress: "",
      CCTFL: "",
      CallId: "",
      followUpBy: "",
      followUpDays: "",
    });
  };
  const handleAuthFileToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSubStatusList([]);
    setIsAuthFile(value);
    setFormData({
      ...formData,
      IsAuthFile: value,
      ActionId: "",
      IsAuthOnCall: "",
      doesDOSFall: "",
      ActionModeId: "",
      IsRepProcessing: "",
      ETA: "",
      isOtherInsurance: "",
      InsuranceName: "",
      IsPolicyActive: "",
      AppealLimit: "",
      AppealAddress: "",
      CCTFL: "",
      CallId: "",
      followUpBy: "",
      followUpDays: "",
    });
    const data = value === "Agree" ? 0 : 5;
    // if (value === "Agree") {
    const subStatusList = subStatusJson
      ?.filter((stts) => stts.value === data)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
    setSubStatusList(subStatusList);
  };
  const handleAuthOnCallToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSubStatusList([]);
    setIsAuthOnCall(value);
    setFormData({
      ...formData,
      IsAuthOnCall: value,
      ActionId: "",
      ActionModeId: "",
      IsRepProcessing: "",
      ETA: "",
      isOtherInsurance: "",
      InsuranceName: "",
      IsPolicyActive: "",
      AppealLimit: "",
      AppealAddress: "",
      CCTFL: "",
      CallId: "",
      followUpBy: "",
      followUpDays: "",
    });
    const data = value === "Agree" ? 3 : 4;
    // if (value === "Agree") {
    const subStatusList = subStatusJson
      ?.filter((stts) => stts.value === data)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
    setSubStatusList(subStatusList);
  };
  const handleReprocessingToggle = (value) => {
    setSelectedSubStatus("");
    setSelectedAction("");
    setSubStatusList([]);
    setReprocessingToggle(value);
    setFormData({
      ...formData,
      IsRepProcessing: value,
      ETA: "",
      isOtherInsurance: "",
      InsuranceName: "",
      IsPolicyActive: "",
      AppealLimit: "",
      AppealAddress: "",
      CCTFL: "",
      CallId: "",
      followUpBy: "",
      followUpDays: "",
    });

    const data = value === "Agree" ? 1 : 2;
    // if (value === "Agree") {
    const subStatusList = subStatusJson
      ?.filter((stts) => stts.value === data)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
    setSubStatusList(subStatusList);
  };

  const handleSaveDisposition = async () => {
    const formObj = {
      caseId: props?.taskDetails?.id,
      visitId: props?.taskDetails?.visitId,
      dispositionBody: JSON.stringify(formData),
      dispositionRemark: stickyNoteContent,
      modifiedBy: auth.id,
    };
    console.log(formObj);
    try {
      const response = await axiosPrivate.post(
        "Task/case-disposition",
        formObj
      );
      props?.setPendingTasks(response.data.result.pendingTasks);
      props?.setTaskSummary(
        response?.data?.result?.taskBucketSummary?.taskBucketDetails
      );
      props?.setTeamLeadQueries(response?.data?.result?.tlQueries);
      props?.setParkedTasks(response?.data?.result?.parkedTasks);
      props?.setMyTask(response?.data?.result);
      props.setShowTasksDetails({
        showTaskDetails: false,
        taskId: "",
      });
      ToastifyService.success("Case Disposed SuccessFully");
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const [stickyNoteContent, setStickyNoteContent] = useState("");

  const generateDynamicContent = () => {
    let content = "";

    content += `Visit Number ${props?.taskDetails?.visitId} `;
    if (formData.status) {
      content += `Claim Denied as ${formData.statusName}. `;
    }
    if (formData.dateOdDenial) {
      content += `On ${formData.dateOdDenial}. `;
    }
    if (formData.claimId) {
      content += `Claim # ${formData.claimId}. `;
    }
    if (formData.isInNetwork === "yes") {
      content += `Claim is processed as In network. `;
    } else if (formData.isInNetwork === "no") {
      content += `Claim is processed as Out of network. `;
    }
    if (formData.IsAuthReq === "no") {
      content += `As per Payer Auth is not required. `;
    } else if (formData.IsAuthReq === "yes") {
      content += `As per Payer Auth is required. `;
      if (formData.IsAuthFile === "Disagree") {
        content += `Checked System, Auth not found on file. `;
      } else if (formData.IsAuthFile === "Agree") {
        content += `Checked System, Auth found on file. `;
      }
    }
    if (formData.IsAuthFile === "Agree") {
      if (formData.IsAuthOnCall === "Disagree") {
        content += `Rep denied to take Auth details over call. `;
      } else if (formData.IsAuthOnCall === "Agree") {
        content += `Rep agreed to take Auth details over call. `;
      }
    }
    if (formData.IsRepProcessing === "Agree") {
      content += `Rep agreed to reprocess the claim. `;
    } else if (formData.IsRepProcessing === "Disagree") {
      content += `Rep denied to reprocess the claim. `;
    }
    if (
      formData.IsRepProcessing === "Agree" ||
      formData.IsAuthOnCall === "Agree"
    ) {
      content += `It can take upto ${formData.ETA} Days to reprocess. `;
    }
    if (formData.AppealLimit) {
      content += `Need to Appeal. Appeal limit is ${formData.AppealLimit} Days. `;
    }
    if (formData.AppealAddress) {
      content += `Appeal from ${formData.AppealAddress}. `;
    }
    if (formData.IsAuthOnCall === "Disagree") {
      content += `Need to update Auth on claim and resubmit a corrected claim. AUTH AVAILABLE. SUBMIT CORRECTED CLAIM Claim can be submitted within ${formData.CCTFL}. `;
    }
    if (formData.CallId) {
      content += `Call Referrence# ${formData.CallId}. `;
    }
    if (formData.ActionId === 1) {
      content += `Claim sent for reprocessing. `;
    } else if (formData.ActionId === 2) {
      content += `Appeal sent to payer. `;
    } else if (formData.ActionId === 3) {
      content += `Claim sent for reprocessing. `;
    } else if (formData.ActionId === 4) {
      content += `Corrected claim sent to insurance. `;
    } else if (formData.ActionId === 5) {
      content += `Reconsidration request sent to payer. `;
    }

    // Update the state with the generated dynamic content
    setStickyNoteContent(content);
  };
  // Call the function to generate the initial content when component mounts
  useEffect(() => {
    generateDynamicContent();
  }, [props.taskDetails, formData]);
  return (
    <React.Fragment>
      <Col md={12}>
        {/* Denial Status Start */}

        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Date Of Denial:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="date"
              name="dateOdDenial"
              value={formData.dateOdDenial}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  dateOdDenial: e.target.value,
                })
              }
            ></Form.Control>
          </Col>
        </Row>
        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Claim #:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Enter Claim #"
              name="claimId"
              value={formData.claimId}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  claimId: e.target.value,
                })
              }
            ></Form.Control>
          </Col>
        </Row>

        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Claim Processed as:</Form.Label>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                variant={
                  selectedToggle === "yes" ? "primary" : "outline-primary"
                }
                onClick={() => handleToggle("yes")}
              >
                <input
                  type="radio"
                  name="isInNetwork"
                  className="btn-check"
                  autoComplete="off"
                  checked={selectedToggle === "yes"}
                  onChange={() => handleToggle("yes")}
                />
                In Network
              </Button>
              <Button
                variant={
                  selectedToggle === "no" ? "primary" : "outline-primary"
                }
                onClick={() => handleToggle("no")}
              >
                <input
                  type="radio"
                  name="isInNetwork"
                  className="btn-check"
                  autoComplete="off"
                  checked={selectedToggle === "no"}
                  onChange={() => handleToggle("no")}
                />
                Out Of Network
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="mb-4 align-items-center">
          <Col md={12}>
            <Form.Label>Is Auth Required?</Form.Label>
          </Col>
          <Col>
            <ButtonGroup>
              <Button
                variant={
                  isAuthRequired === "yes" ? "primary" : "outline-primary"
                }
                onClick={() => handleAuthToggle("yes")}
              >
                <input
                  type="radio"
                  name="IsAuthReq"
                  className="btn-check"
                  autoComplete="off"
                  checked={isAuthRequired === "yes"}
                  onChange={() => handleAuthToggle("yes")}
                />
                Yes
              </Button>
              <Button
                variant={
                  isAuthRequired === "no" ? "primary" : "outline-primary"
                }
                onClick={() => handleAuthToggle("no")}
              >
                <input
                  type="radio"
                  name="IsAuthReq"
                  className="btn-check"
                  autoComplete="off"
                  checked={isAuthRequired === "no"}
                  onChange={() => handleAuthToggle("no")}
                />
                No
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        {/* Does DOS falls between effective and termination Date : yes  (start)*/}
        {isAuthRequired === "no" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Ask rep to reprocess the claim:</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    reprocessinToggle === "Agree"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => handleReprocessingToggle("Agree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={reprocessinToggle === "Agree"}
                    onChange={() => handleReprocessingToggle("Agree")}
                  />
                  Agree
                </Button>
                <Button
                  variant={
                    reprocessinToggle === "Disagree"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => handleReprocessingToggle("Disagree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={reprocessinToggle === "Disagree"}
                    onChange={() => handleReprocessingToggle("Disagree")}
                  />
                  Disagree
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {isAuthRequired === "yes" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Check if we have Auth on File:</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    isAuthFile === "Agree" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleAuthFileToggle("Agree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={isAuthFile === "Agree"}
                    onChange={() => handleAuthFileToggle("Agree")}
                  />
                  Yes
                </Button>
                <Button
                  variant={
                    isAuthFile === "Disagree" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleAuthFileToggle("Disagree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={isAuthFile === "Disagree"}
                    onChange={() => handleAuthFileToggle("Disagree")}
                  />
                  No
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {isAuthFile === "Agree" && (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Form.Label>Can Rep Take Auth on Call:</Form.Label>
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    isAuthOnCall === "Agree" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleAuthOnCallToggle("Agree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={isAuthOnCall === "Agree"}
                    onChange={() => handleAuthOnCallToggle("Agree")}
                  />
                  Yes
                </Button>
                <Button
                  variant={
                    isAuthOnCall === "Disagree" ? "primary" : "outline-primary"
                  }
                  onClick={() => handleAuthOnCallToggle("Disagree")}
                >
                  <input
                    type="radio"
                    name="options"
                    className="btn-check"
                    autoComplete="off"
                    checked={isAuthOnCall === "Disagree"}
                    onChange={() => handleAuthOnCallToggle("Disagree")}
                  />
                  No
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
        {subStatusList && subStatusList.length !== 0 && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>
                  Sub Status <span className="text-danger">*</span>
                </Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleSubStatusSelect}
                  options={subStatusList}
                  name="SubStatus"
                  value={selectedSubStatus}
                  isClearable
                />
              </Col>
            </Row>
          </>
        )}

        {/* Agree REPROCESSING start */}
        {(reprocessinToggle === "Agree" ||
          (isAuthFile === "Agree" && isAuthOnCall === "Agree")) && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>ETA for reprocess:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="ETA for reprocess"
                  name="ETA"
                  value={formData.ETA}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ETA: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Call Referrence #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Call Referrence #"
                  name="CallId"
                  value={formData.CallId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CallId: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {/* Agre REPROCESSING end  */}

        {/* Disagree REPROCESSING start */}
        {(reprocessinToggle === "Disagree" || isAuthFile === "Disagree") && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Appeal Limit:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Appeal Limit"
                  name="AppealLimit"
                  value={formData.AppealLimit}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      AppealLimit: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row className="mb-4 ">
              <Col md={12}>
                <Form.Label>Appeals Address:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter Appeals Address"
                  name="AppealAddress"
                  value={formData.AppealAddress}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      AppealAddress: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Required Documents:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Required Documents"
                />
              </Col>
            </Row>

            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Call Referrence #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Call Referrence #"
                  name="CallId"
                  value={formData.CallId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CallId: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {/* Disagree REPROCESSING end */}

        {isAuthFile === "Agree" && isAuthOnCall === "Disagree" && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>CC TFL:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter CC TFL"
                  name="CCTFL"
                  value={formData.CCTFL}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CCTFL: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Call Referrence #:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Call Referrence #"
                  name="CallId"
                  value={formData.CallId}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      CallId: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {subStatusList && subStatusList.length !== 0 && (
          <>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Action:</Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleActionSelect}
                  options={filteredActionData}
                  name="ActionId"
                  value={selectedAction}
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Action Mode:</Form.Label>
              </Col>
              <Col>
                <Select
                  onChange={handleActionModeSelect}
                  options={filteredActionModeData}
                  name="ActionModeId"
                  value={selectedActionMode}
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Follow Up Days:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Follow Up Days"
                  name="followUpDays"
                  value={formData.followUpDays}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      followUpDays: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Col md={12}>
                <Form.Label>Next FollowUp By:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Next FollowUp By"
                  name="followUpBy"
                  value={formData.followUpBy}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      followUpBy: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {/* Denial Status End  */}

        {/* Does DOS falls between effective and termination Date : yes  (End)*/}
      </Col>

      <Col md={12}>
        <Row className="mb-4">
          <Col md={12}>
            <Form.Label>Automated Notes:</Form.Label>
          </Col>
          <Col>
            {/* <div className="disposition-sticky-note" ref={myRef} contentEditable={true}
                suppressContentEditableWarning={true}>
                <span className="text-dark fw-semibold fs-12 lh-28">
                  {`Visit Number ${props?.taskDetails?.visitId} `}
                  {formData.status && `Claim Denied as `}{" "}
                  <b>{formData.statusName} </b>
                  {formData.dateOdDenial && " On"}{" "}
                  <b>{formData.dateOdDenial} </b>{" "}
                  {formData.claimId && "Claim # "}
                  <b>{formData.claimId} </b>
                  {formData.isInNetwork === "yes" &&
                    `Claim is processed as In network `}
                  {formData.isInNetwork === "no" &&
                    `Claim is processed as Out of network `}
                  {formData.IsAuthReq === "no" &&
                    `As per Payer Auth is not required`}
                  {formData.IsAuthReq === "yes" &&
                    `As per Payer Auth is required`}
                  {formData.IsAuthReq === "yes" &&
                    formData.IsAuthFile === "Disagree" &&
                    ` Checked System, Auth not found on file `}
                  {formData.IsAuthReq === "yes" &&
                    formData.IsAuthFile === "Agree" &&
                    ` Checked System, Auth found on file `}
                  {formData.IsAuthFile === "Agree" &&
                    formData.IsAuthOnCall === "Disagree" &&
                    `Rep denied to take Auth details over call `}
                  {formData.IsAuthFile === "Agree" &&
                    formData.IsAuthOnCall === "Agree" &&
                    `Rep agreed to take Auth details over call `}
                  {formData.IsRepProcessing === "Agree" &&
                    ". Rep agreed to reprocess the claim"}
                  {formData.IsRepProcessing === "Disagree" &&
                    ". Rep denied to reprocess the claim"}
                  {(formData.IsRepProcessing === "Agree" ||
                    formData.IsAuthOnCall === "Agree") &&
                    formData.ETA &&
                    `. It can take upto ${formData.ETA} Days to reprocess`}
                  {formData.AppealLimit &&
                    ` Need to Appeal. Appeal limit is ${formData.AppealLimit} Days`}
                  {formData.AppealAddress && ` from ${formData.AppealAddress}`}
                  {formData.IsAuthOnCall === "Disagree" &&
                    `Need to update Auth on claim and resubmit a corrected claim AUTH AVAILABLE. SUBMIT CORRECTED CLAIM Claim can be submitted within ${formData.CCTFL} `}
                  {formData.CallId && ". Call Referrence#"}{" "}
                  <b>{formData.CallId}</b>
                  {formData.ActionId === 1 && `. Claim sent for reprocessing`}
                  {formData.ActionId === 2 && `. Appeal sent to payer`}
                  {formData.ActionId === 3 && `. Claim sent for reprocessing`}
                  {formData.ActionId === 4 &&
                    `. Corrected claim sent to insurance`}
                  {formData.ActionId === 5 &&
                    `. Reconsidration request sent to payer `}
                </span>
              </div> */}
            <div
              className="disposition-sticky-note"
              contentEditable={true}
              suppressContentEditableWarning={true}
            >
              <span className="text-dark fw-semibold fs-12 lh-28">
                {/* Concatenate dynamic content and user-editable content */}
                {stickyNoteContent}
                <span
                  className="user-editable-content"
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                ></span>
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <div className="decision-tree-save-bttn">
        <Button variant="primary" onClick={handleSaveDisposition}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
}

export default DAuthorization;
