const defaultValue = "00000000-0000-0000-0000-000000000000";
const status = {
  active: "4e0073a0-2337-4acf-bbd7-6b265bed7f01",
};
const taskRuleStatus = {
  published: "48f67ff4-6152-4a15-9294-476f09a73e77",
  draft: "48f67ff4-6152-4a15-9294-476f09a73e76",
};
const pendingQuerySts = {
  payerClosed: "48F67FF4-6152-4A15-9294-476F09A71E08",
  workLater: "48F67FF4-6152-4A15-9294-476F09A71E09",
  TLQuery: "48F67FF4-6152-4A15-9294-476F09A71E10",
};
const userRoles={
  teamLead:'42512cb8-b979-456d-b0fa-c6d39881bc7e'
}
const parkStatus={
  payerClosed:'48F67FF4-6152-4A15-9294-476F09A71E08',
  tlQuery:"48F67FF4-6152-4A15-9294-476F09A71E10",
  workLater:"48F67FF4-6152-4A15-9294-476F09A71E09"
}
const workQueueTypeStatus={
  available:'5e03965f-c410-46b3-8713-b5058348ab01',
  notAvailable:"5e03965f-c410-46b3-8713-b5058348ab02",
}
export default {
  defaultValue,
  status,
  taskRuleStatus,
  pendingQuerySts,
  userRoles,
  parkStatus,
  workQueueTypeStatus
};
